<template>
  <b-card title="Add Division">
    <loading :active="isLoading" loader="bars" color="#7367f0" :is-full-page="fullPage" />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="form">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ministry" label-for="ministry">
              <validation-provider #default="{ errors }" name="Ministry" rules="required">
                <v-select v-model="ministry" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="ministryOptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Name" label-for="name">
              <validation-provider #default="{ errors }" name="Name" rules="required">
                <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false:null" placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="description">
              <b-form-textarea id="description" v-model="description" placeholder="Description" rows="3" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Ministry Type" label-for="ministryType">
              <validation-provider #default="{ errors }" name="Ministry Type" rules="required">
                <v-select v-model="ministryType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="ministryTypeOptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Service Type" label-for="serviceType">
              <validation-provider #default="{ errors }" name="Service Type" rules="required">
                <v-select v-model="serviceType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="serviceTypeOptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mr-1" type="submit" @click.prevent="validationForm">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              :to="{ name: 'servolution-divisions' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      name: '',
      description: null,
      ministry: [],
      ministryOptions: [],
      ministryType: [],
      ministryTypeOptions: [],
      serviceType: [],
      serviceTypeOptions: [{
        name: 'Daily',
      }, {
        name: 'Weekly',
      }],
      required,
    }
  },
  mounted() {
    // Get list of ministries
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/ministries`)
      .then(ministries => {
          _.forEach(ministries.data, ministry => {
              this.ministryOptions.push({ id: ministry.id, name: ministry.name })
          })
      })

    // Get list of ministry types
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/ministry/types`)
      .then(ministryTypes => {
          _.forEach(ministryTypes.data, ministryType => {
              this.ministryTypeOptions.push({ id: ministryType.id, name: ministryType.name })
          })
      })
  },
  methods: {
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('add', 'servolution_divisions')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Set the data
          const data = {
            name: this.name,
            description: this.description ? this.description : null,
            ministryId: this.ministry.id,
            ministryTypeId: this.ministryType.id,
            serviceType: this.serviceType.name,
          }

          // Save and redirect
          this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/division`, data)
            .then(() => {
              this.isLoading = false

              // Redirect to divisions lists
              this.$router.replace({ name: 'servolution-divisions' })
                .then(() => {
                  // Show message
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Data has been saved successfully.',
                    },
                  })
                })
            })
        }
      })
    },
  },
}
</script>